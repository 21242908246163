import { useMemo } from "react";
export * from "./CertifiedTable";

export const useColumns = (): any => {
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id_local",
      },
      {
        Header: "Empleador",
        accessor: "company_name",
      },
      {
        Header: "Nivel",
        accessor: "level",
      },
      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Header: "Link Certificado",
        accessor: "link",
      },
    ],
    []
  );

  return columns;
};

export const dataFake: any = [
  {
    id: "044",
    level: "A3",
    document_number: 12042564,
    empleator: "Redeban",
    name: "Antonio",
  },
  {
    id: "045",
    level: "A2",
    document_number: 1000575708,
    empleator: "Redeban",
    name: "Andres",
  },
  {
    id: "047",
    level: "A2",
    document_number: 1000575708,
    empleator: "Redeban",
    name: "Andres",
  },
  {
    id: "0451",
    level: "A3",
    document_number: 1000575708,
    empleator: "Redeban",
    name: "Andres",
  },
  {
    id: "0452",
    level: "A3",
    document_number: 1000575708,
    empleator: "Redeban",
    name: "Andres",
  },
  {
    id: "046",
    level: "A3",
    document_number: 100057454,
    empleator: "Redeban",
    name: "Rafael",
  },
  {
    id: "RE-2700",
    empleator: "CONSTRUCCIONES DURANGO SAS",
    name: "SAMUEL ENRIQUE GARCIAS BETANCOUR",
    document_number: 10952960,
    date: "1-1-2023",
    Column6: 10952960,
  },
  {
    id: "RE-2701",
    empleator: "CONSTRUCCIONES DURANGO SAS",
    name: "ELIAS FONSECA CASTIBLANCO",
    document_number: 80067749,
    date: "1-1-2023",
    Column6: 80067749,
  },
  {
    id: "RE-2702",
    empleator: "MOON INGENIERIA SAS",
    name: "MARCO ALEXANDER SALAMANCA DELGADO",
    document_number: 80539217,
    date: "3-1-2023",
    Column6: 80539217,
  },
  {
    id: "RE-2703",
    empleator: "DEMOVICOL SAS",
    name: "LUIS JESUS PEREZ AVILA",
    document_number: 1094859150,
    date: "1-1-2023",
    Column6: 1094859150,
  },
  {
    id: "RE-2704",
    empleator: "FSC INGENIEROS SAS",
    name: "CRISTIAN FABIAN IBAÑEZ OCHOA",
    document_number: 1024492577,
    date: "4-1-2023",
    Column6: 1024492577,
  },
  {
    id: "RE-2705",
    empleator: "INNOVAR ARSA SAS",
    name: "JEIMY ANDREA TORRES MOLINA",
    document_number: 1022328686,
    date: "4-1-2023",
    Column6: 1022328686,
  },
  {
    id: "RE-2706",
    empleator: "CONSTRUCCIONES DURANGO SAS",
    name: "JOSE GREGORIO MARTINEZ MARTINEZ",
    document_number: 1087130171,
    date: "1-1-2023",
    Column6: 1087130171,
  },
  {
    id: "RE-2707",
    empleator: "CONSTRUCCIONES DURANGO SAS",
    name: "YASMANI MANUEL SOTELO ALGARIN",
    document_number: 1069479967,
    date: "1-1-2023",
    Column6: 1069479967,
  },
  {
    id: "RE-2708",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "MANUEL DE JESUS VILLEGAS MONTES",
    document_number: 10882811,
    date: "5-1-2023",
    Column6: 10882811,
  },
  {
    id: "RE-2709",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "JAIME ALBERTO TALAIGUA AVILA",
    document_number: 15683923,
    date: "5-1-2023",
    Column6: 15683923,
  },
  {
    id: "RE-2710",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "MIGUEL CHAVEZ SOTO",
    document_number: 1003274855,
    date: "5-1-2023",
    Column6: 1003274855,
  },
  {
    id: "RE-2711",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "CARLOS FERNANDO ORTIZ RANGEL",
    document_number: "77195459",
    date: "5-1-2023",
    Column6: "CC 77195459",
  },
  {
    id: "RE-2712",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "EDWIN ENRIQUE RUIZ GUZMAN",
    document_number: "1070596447",
    date: "5-1-2023",
    Column6: "CC 1070596447",
  },
  {
    id: "RE-2713",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "DIEGO EDISON COY RUIZ",
    document_number: "11229018",
    date: "5-1-2023",
    Column6: "CC 11229018",
  },
  {
    id: "RE-2714",
    empleator: "MOON INGENIERIA SAS",
    name: "DUFRAY ANDRES ARDILA BELTRAN",
    document_number: "1113040937",
    date: "2-1-2023",
    Column6: "CC 1113040937",
  },
  {
    id: "TA-2715",
    empleator: "AISLARTE SAS",
    name: "RUBEN DARIO HERNANDEZ MORENO",
    document_number: "1073669734",
    date: "2-1-2023",
    Column6: "CC 1073669734",
  },
  {
    id: "TA-2716",
    empleator: "MULTILIMPIEZAS Y SOLUCIONES SAS",
    name: "ANGEL FARID VARGAS SAJAYO",
    document_number: "1193232175",
    date: "2-1-2023",
    Column6: "CC 1193232175",
  },
  {
    id: "RE-2717",
    empleator: "VOLADURAS Y PERFORACIONES CHAVARRIA SAS",
    name: "JOHN FREDY AREIZA",
    document_number: "71411451",
    date: "3-1-2023",
    Column6: "CC 71411451",
  },
  {
    id: "TA-2718",
    empleator: "ALUMINIO ARQUITECTONICO JETER SAS",
    name: "JAVIER ANDRES ALFONSO RODRIGUEZ",
    document_number: "1014192708",
    date: "2-1-2023",
    Column6: "CC 1014192708",
  },
  {
    id: "TA-2719",
    empleator: "EMPRESA POWER SERVICES LTDA",
    name: "JOHN ALBERTO HERRERA VIRACACHA",
    document_number: "79810829",
    date: "2-1-2023",
    Column6: "CC 79810829",
  },
  {
    id: "AC-2720",
    empleator: "FSC INGENIEROS SAS",
    name: "CRISTIAN FABIAN IBAÑEZ OCHOA",
    document_number: "1024492577",
    date: "5-1-2023",
    Column6: "CC 1024492577",
  },
  {
    id: "TA-2721",
    empleator: "ALUMINIO ARQUITECTONICO JETER SAS",
    name: "SANDRA VIVIANA PEÑALOZA GONZALEZ",
    document_number: "1026550954",
    date: "2-1-2023",
    Column6: "CC 1026550954",
  },
  {
    id: "TA-2722",
    empleator: "MOON INGENIERIA SAS",
    name: "MAURICIO ALEJANDRO AGUIRRE ACEVEDO",
    document_number: "1112098796",
    date: "2-1-2023",
    Column6: "CC 1112098796",
  },
  {
    id: "TA-2723",
    empleator: "C&F CONSULTORIA Y CONSTRUCCION SAS",
    name: "OSCAR JAVIER GOYONECHE VILLAREAL",
    document_number: "1019108044",
    date: "2-1-2023",
    Column6: "CC 1019108044",
  },
  {
    id: "TA-2724",
    empleator: "C&F CONSULTORIA Y CONSTRUCCION SAS",
    name: "FRANCISCO JAVIER TERNERA OSPINO",
    document_number: "1079940198",
    date: "2-1-2023",
    Column6: "CC 1079940198",
  },
  {
    id: "RE-2725",
    empleator: "CONEXIONES DE NEGOCIOS LTDA",
    name: "MAICOL ESTIVENS SALAMANCA CELY",
    document_number: "1024485759",
    date: "6-1-2023",
    Column6: "CC 1024485759",
  },
  {
    id: "TA-2726",
    empleator: "INDUSTRIA METALICA JSP SAS",
    name: "LUIS CARLOS VARGAS RINCON",
    document_number: "79770593",
    date: "2-1-2023",
    Column6: "CC 79770593",
  },
  {
    id: "RE-2727",
    empleator: "GAIA GESTION AMBIENTAL SAS",
    name: "PABLO ANTONIO DURAN VALENCIA",
    document_number: "88156463",
    date: "7-1-2023",
    Column6: "CC 88156463",
  },
  {
    id: "RE-2728",
    empleator: "MOON INGENIERIA SAS",
    name: "BRAYAN ANDRES VASQUEZ AGUIRRE",
    document_number: "1112106824",
    date: "2-1-2023",
    Column6: "CC 1112106824",
  },
  {
    id: "RE-2729",
    empleator: "GAIA GESTION AMBIENTAL SAS",
    name: "LUIS AURELIO LARA BORDA",
    document_number: "3111295",
    date: "5-1-2023",
    Column6: "CC 3111295",
  },
  {
    id: "TA-2730",
    empleator: "SISTEMA CONSTRUCTIVO SIACHICA SAS",
    name: "JONATHAN EDUARDO CHAVARRO GALARZA",
    document_number: "1019027108",
    date: "2-1-2023",
    Column6: "CC 1019027108",
  },
  {
    id: "TA-2731",
    empleator: "SISTEMA CONSTRUCTIVO SIACHICA SAS",
    name: "MANUEL FERNANDO CHAVARRO GALARZA",
    document_number: "1019055377",
    date: "2-1-2023",
    Column6: "CC 1019055377",
  },
  {
    id: "TA-2732",
    empleator: "SISTEMA CONSTRUCTIVO SIACHICA SAS",
    name: "EDWIN GIOVANNY PEREZ LOPEZ",
    document_number: "80800900",
    date: "2-1-2023",
    Column6: "CC 80800900",
  },
  {
    id: "TA-2733",
    empleator: "CARLOS ARTURO SALDARRIAGA PARDO",
    name: "CRISTIAN CAMILO RODRIGUEZ OROZCO",
    document_number: "1110567128",
    date: "2-1-2023",
    Column6: "CC 1110567128",
  },
  {
    id: "TA-2734",
    empleator: "CARLOS ARTURO SALDARRIAGA PARDO",
    name: "WALTER ALONSO MAHECHA TORRES",
    document_number: "1110514545",
    date: "2-1-2023",
    Column6: "CC 1110514545",
  },
  {
    id: "TA-2735",
    empleator: "ANDAFID URBANISMO SAS",
    name: "NILLER JOSE ARISTIMUÑO",
    document_number: "1670548",
    date: "2-1-2023",
    Column6: "PP 1670548",
  },
  {
    id: "RE-2736",
    empleator: "CAP INVERSIONES CONSTRUCCIONES LTDA",
    name: "JIMMY HONORIO URREGO GUERRERO",
    document_number: "80737318",
    date: "5-1-2023",
    Column6: "CC 80737318",
  },
  {
    id: "RE-2737",
    empleator: "CAP INVERSIONES CONSTRUCCIONES LTDA",
    name: "ANGEL HUMBERTO URREGO GUERRERO",
    document_number: "79731043",
    date: "5-1-2023",
    Column6: "CC 79731043",
  },
  {
    id: "RE-2738",
    empleator: "CAP INVERSIONES CONSTRUCCIONES LTDA",
    name: "FRANK FERNELY DIAZ CACERES",
    document_number: "1024495183",
    date: "5-1-2023",
    Column6: "CC 1024495183",
  },
  {
    id: "RE-2739",
    empleator: "CONSTRUCTORA PULIDO SAS",
    name: "OSCAR MARIÑO VALERO",
    document_number: "79598711",
    date: "7-1-2023",
    Column6: "CC 79598711",
  },
  {
    id: "TA-2740",
    empleator: "MISION ESTRUCTURAL SAS",
    name: "HERNAN ANTONIO ARRIETA MARTINEZ",
    document_number: "92503173",
    date: "10-1-2023",
    Column6: "CC 92503173",
  },
  {
    id: "TA-2741",
    empleator: "MISION ESTRUCTURAL SAS",
    name: "ALEXANDER ENRRIQUE RIVAS SALCEDO",
    document_number: "5221630",
    date: "10-1-2023",
    Column6: "PP 5221630",
  },
  {
    id: "RE-2742",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "BENYI FIDEL BARRIOS DIAZ",
    document_number: "1002102557",
    date: "6-1-2023",
    Column6: "CC 1002102557",
  },
  {
    id: "RE-2743",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "TOMAS ALFONSO PADILLA MARTINEZ",
    document_number: "11000664",
    date: "6-1-2023",
    Column6: "CC 11000664",
  },
  {
    id: "RE-2744",
    empleator: "CONSTRUCCIONES DURANGO SAS",
    name: "JULIO CESAR MEDRANO MESTRA",
    document_number: "10999433",
    date: "10-1-2023",
    Column6: "CC 10999433",
  },
  {
    id: "TA-2745",
    empleator: "DAKFER CONSTRUCCIONES SAS",
    name: "DANIEL ALEJANDRO HOLLOS ARMESTO",
    document_number: "1046427458",
    date: "10-1-2023",
    Column6: "CC 1046427458",
  },
  {
    id: "RE-2746",
    empleator: "SISSA CONSTRUCCIONES SAS",
    name: "ADALBER FLOREZ CONDE",
    document_number: "84007728",
    date: "10-1-2023",
    Column6: "CC 84007728",
  },
  {
    id: "RE-2747",
    empleator: "SISSA CONSTRUCCIONES SAS",
    name: "LUIS ALBERTO LOPEZ CASTILLO",
    document_number: "1020754739",
    date: "10-1-2023",
    Column6: "CC 1020754739",
  },
  {
    id: "RE-2748",
    empleator: "SISSA CONSTRUCCIONES SAS",
    name: "ADOLFO CAMPO HURTADO",
    document_number: "9282397",
    date: "10-1-2023",
    Column6: "CC 9282397",
  },
  {
    id: "RE-2749",
    empleator: "SISSA CONSTRUCCIONES SAS",
    name: "EUDORO VILLARRAGA ROA",
    document_number: "86050943",
    date: "10-1-2023",
    Column6: "CC 86050943",
  },
  {
    id: "RE-2750",
    empleator: "SISSA CONSTRUCCIONES SAS",
    name: "AUDENAGO BALLESTEROS CORREA",
    document_number: "4194012",
    date: "10-1-2023",
    Column6: "CC 4194012",
  },
  {
    id: "AC-001",
    "TIPO DOC": "CC",
    document_number: 1030699942,
    name: "VALERIA ARANGO FIGUEROA",
    empleator: "INGENIERIA CIVIL MECANICA Y ESPACIOS COMERCIALES SAS",
    level: "ROL TRABAJADOR AUTORIZADO",
    date: "17 FEB 2024",
  },
  {
    id: "AC-002",
    "TIPO DOC": "CC",
    document_number: 1053323796,
    name: "LUIS ALFONSO NIETO REYES",
    empleator: "INTERSERVICIOS GYC LTDA",
    level: "ROL REENTRENAMIENTO",
    date: "18 FEB 2024",
  },
  {
    id: "AC-003",
    "TIPO DOC": "CC",
    document_number: 9430557,
    name: "LUIS FERNANDO NIÑO GONZALEZ",
    empleator: "INTERSERVICIOS GYC LTDA",
    level: "ROL REENTRENAMIENTO",
    date: "18 FEB 2024",
  },
  {
    id: "AC-004",
    "TIPO DOC": "CC",
    document_number: 1001347013,
    name: "DAVID LEONARDO HEREDIA ROMERO",
    empleator: "INGENIERIA CIVIL MECANICA Y ESPACIOS COMERCIALES SAS",
    level: "ROL TRABAJADOR AUTORIZADO",
    date: "17 FEB 2024",
  },
  {
    id: "AC-005",
    "TIPO DOC": "CC",
    document_number: 1100011140,
    name: "DANILO ANTONIO DIAZ ANAYA",
    empleator: "G&M ACABADOS Y ENCHAPES SAS",
    level: "ROL TRABAJADOR AUTORIZADO",
    date: "17 FEB 2024",
  },
  {
    id: "AC-006",
    "TIPO DOC": "CC",
    document_number: 1019116073,
    name: "DIEGO MIRANDA ORTIZ",
    empleator: "G&M ACABADOS Y ENCHAPES SAS",
    level: "ROL TRABAJADOR AUTORIZADO",
    date: "17 FEB 2024",
  },
];
